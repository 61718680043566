body {
    background: #fff;
    color: #2f3138;
    font-family: "Open Sans", sans-serif;
}

a {
    color: #f82249;
    transition: 0.5s;
}

a:hover,
a:active,
a:focus {
    color: #f8234a;
    outline: none;
    text-decoration: none;
}

#mainPage {
    min-height: calc(100vh - 151px);
}