@import "./../../../shared/styles/theme.scss";

#intro {
    width: 100%;
    height: 75vh;
    // background: url(../../../../assets/images/intro-bg.jpg) top center;
    background: url(../../../../assets/images/intro-bg2.jpg) top center;
    // background: url(../../../../assets/images/subscribe-bg.jpg) top center;
    background-size: cover;
    overflow: hidden;
    position: relative;

    &:before {
        content: "";
        background: rgba(6, 12, 34, 0.25);
        position: absolute;
        bottom: 0;
        top: -50px;
        left: 0;
        right: 0;
    }

    .intro-container {
        position: absolute;
        bottom: 0;
        left: 0;
        top: 90px;
        right: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        padding: 0 15px;
    }

    h1 {
        color: $color_5;
        font-family: $font-family_2;
        font-size: 56px;
        font-weight: 600;
        text-transform: uppercase;

        span {
            color: $color_2;
        }
    }

    p {
        color: $color_9;
        font-weight: 700;
        font-size: 20px;
    }

    .play-btn {
        width: 94px;
        height: 94px;
        background: radial-gradient(#f82249 50%, rgba(101, 111, 150, 0.15) 52%);
        border-radius: 50%;
        display: block;
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 15px solid #fff;
            z-index: 100;
            transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        &:before {
            content: "";
            position: absolute;
            width: 120px;
            height: 120px;
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
            -webkit-animation: pulsate-btn 2s;
            animation: pulsate-btn 2s;
            -webkit-animation-direction: forwards;
            animation-direction: forwards;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-timing-function: steps;
            animation-timing-function: steps;
            opacity: 1;
            border-radius: 50%;
            border: 2px solid rgba(163, 163, 163, 0.4);
            top: -15%;
            left: -15%;
            background: rgba(198, 16, 0, 0);
        }

        &:hover {
            &::after {
                border-left: 15px solid #f82249;
                -webkit-transform: scale(20);
                transform: scale(20);
            }

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translateX(-40%) translateY(-50%);
                transform: translateX(-40%) translateY(-50%);
                width: 0;
                height: 0;
                border: none;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 15px solid #fff;
                z-index: 200;
                -webkit-animation: none;
                animation: none;
                border-radius: 0;
            }
        }
    }

    .about-btn {
        font-family: $font-family_2;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 32px;
        border-radius: 50px;
        transition: 0.5s;
        line-height: 1;
        margin: 10px;
        color: $color_5;
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
        border: 2px solid #f82249;

        &:hover {
            background: #f82249;
            color: $color_5;
        }
    }
}
