$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Raleway", sans-serif;
$font-family_3: FontAwesome;

$color_1: #2f3138;
$color_2: #273b90;
$color_3: lighten(#273b90, 10%);
$color_4: #0e1b4d;
$color_5: #fff;
$color_6: #9195a2;
$color_7: rgba(202, 206, 221, 0.8);
$color_8: #060c22;
$color_9: #ebebeb;
$color_10: #112363;
$color_11: #19328e;
$color_12: #152b79;
$color_13: #ffc31d;
$color_14: #000;
$color_15: #4869df;
$color_16: red;
$color_17: #eee;
$color_18: #ddd;

$background-color_1: #f6f7fd;