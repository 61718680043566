@import "./../../../shared/styles/theme.scss";

#about {
    background-size: cover;
    overflow: hidden;
    position: relative;
    color: $color_8;
    padding: 20px 0 60px 0;

    h2 {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 10px;
        color: $color_8;
    }

    h3 {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $color_8;
    }

    p {
        font-size: 16px;
        margin-bottom: 20px;
        color: $color_8;
    }

    blockquote {
        max-width: 650px;
        background: #f9f9f9;
        border-left: 10px solid #ccc;
        margin: 1.5em auto 1.5em;
        padding: 0.5em 10px;
        quotes: "“" "”" "‘" "’";
    }

    blockquote:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }

    blockquote:after {
        color: #ccc;
        content: close-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.1em;
        float: right;
        margin-top: -0.3em;
    }

    blockquote p {
        display: inline;
    }
}

@media (min-width: 1024px) {
    #about {
        background-attachment: fixed;
    }
}