@import "./styles/theme.scss";

#header {
    height: 90px;
    padding: 25px 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 997;

    #nav_logo {
        h1 {
            font-size: 36px;
            margin: 0;
            padding: 6px 0;
            line-height: 1;
            font-family: $font-family_2;
            font-weight: 700;
            letter-spacing: 3px;
            text-transform: uppercase;

            span {
                color: $color_2;
            }

            a {
                color: $color_5;

                &:hover {
                    color: $color_5;
                }
            }
        }

        img {
            padding: 0;
            margin: 0;
            max-height: 60px;
            transition: all 0.5s;
        }
    }
}

#header.header-scrolled,
#header.header-fixed {
    background: #000000aa;
    height: 80px;
    padding: 5px 0;
    transition: all 0.5s;

    #nav_logo {
        img {
            padding: 0;
            margin: 0;
            max-height: 40px;
            transition: all 0.5s;
        }
    }
}

#header.header-fixed {
    background: #000000cc;
    // background: #0e0080dd;
}

.nav-menu {
    margin: 0;
    margin-left: auto;
    padding: 0;
    list-style: none;

    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        padding: 8px;
        text-decoration: none;
        display: inline-block;
        color: $color_7;
        font-family: $font-family_2;
        font-weight: 600;
        font-size: 14px;
        outline: none;

        &:hover {
            color: $color_5;

            &:before {
                visibility: visible;
                width: 100%;
            }
        }
    }

    .menu-active {
        a {
            color: $color_5;
        }

        > a {
            &:before {
                visibility: visible;
                width: 100%;
            }
        }
    }
}

.sf-arrows {
    .sf-with-ul {
        padding-right: 30px;

        &:after {
            content: "\f107";
            position: absolute;
            right: 15px;
            font-family: $font-family_3;
            font-style: normal;
            font-weight: normal;
        }
    }

    ul {
        .sf-with-ul {
            &:after {
                content: "\f105";
            }
        }
    }
}

@media (max-width: 991px) {
    .navbar {
        padding: 0px 16px 0px 0px;
    }

    .navbar-collapse {
        background: rgba(6, 12, 34, 0.98);
        padding: 8px 0 8px 16px;
    }

    #header {
        height: 70px;
        padding: 0 0;

        #nav_logo {
            img {
                max-height: 40px;
            }
        }
    }
}
