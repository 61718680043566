/*--------------------------------------------------------------
# Logo Section
--------------------------------------------------------------*/
#logo {
    padding: 0 0 30px 0;
}

#logo .logo-container {
    margin-top: -220px;
}

/*--------------------------------
    Social links
--------------------------------*/
.social-links a {
    font-size: 18px;
    display: inline-block;
    background: #273B90;
    color: #eee;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}