@import "./../shared/styles/theme.scss";

#portfolio {
    margin-top: 60px;
    padding: 60px 0 30px 0;

    img {
        width: 33vh;
        max-width: 300px;
    }

    .card {
        border: unset;
        border-left: 10px solid #cccccc;
        background-color: #f9f9f9;
        padding: 10px;
    }

    .card-header,
    .card-footer {
        background-color: transparent;
        border: unset;
    }

    .card-header {
        img {
            width: unset;
            max-width: unset;
        }
    }

    .project-body {
        height: 200px;
    }
}

$btn-background: $color_9;
$btn-border: darken($color_2, 10%);
$btn-color: $color_2;

.btn-outline-custom {
    color: $btn-color;
    background-color: $btn-background;
    background-image: none;
    border-color: $color_9;
}

.btn-outline-custom:hover {
    color: $btn-color;
    background-color: $btn-background;
    border-color: $btn-border;
}

.btn-outline-custom:focus,
.btn-outline-custom.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-custom.disabled,
.btn-outline-custom:disabled {
    color: $btn-color;
    background-color: $btn-background;
}

.btn-outline-custom:not(:disabled):not(.disabled):active,
.btn-outline-custom:not(:disabled):not(.disabled).active,
.show>.btn-outline-custom.dropdown-toggle {
    color: $btn-color;
    background-color: $btn-background;
    border-color: $btn-border;
}

.btn-outline-custom:not(:disabled):not(.disabled):active:focus,
.btn-outline-custom:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-custom.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}