@import "./styles/theme.scss";

#footer {
    background: #0e008016;
    padding: 8px 0;
    color: $color_1;
    font-size: 12px;
    margin-top: 50px;

    &.visible {
        position: fixed;
        bottom: 0;
        width: 100vw;
    }

    .copyright {
        text-align: center;
    }

    .credits {
        text-align: center;
        font-size: 13px;
        color: $color_18;
    }
}
