@import "./pages/shared/styles/theme.scss";

@-webkit-keyframes pulsate-btn {
  0% {
    -webkit-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    -webkit-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

body {
  background: #fff;
  color: $color_1;
  font-family: $font-family_1;
}

a {
  color: $color_2;
  transition: 0.5s;

  &:hover {
    color: $color_3;
    outline: none;
    text-decoration: none;
  }

  &:active {
    color: $color_3;
    outline: none;
    text-decoration: none;
  }

  &:focus {
    color: $color_3;
    outline: none;
    text-decoration: none;
  }
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1 {
  font-family: $font-family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: $color_4;
}

h2 {
  font-family: $font-family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: $color_4;
}

h3 {
  font-family: $font-family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: $color_4;
}

h4 {
  font-family: $font-family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: $color_4;
}

h5 {
  font-family: $font-family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: $color_4;
}

h6 {
  font-family: $font-family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: $color_4;
}

.main-page {
  margin-top: 70px;
}

.section-header {
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 20px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 180px;
    height: 5px;
    background: $color_2;
    bottom: 0;
    left: calc(50% - 90px);
  }

  h2 {
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: $color_6;
  }
}

.section-with-bg {
  background-color: $background-color_1;
}

@media (min-width: 1024px) {
  #intro {
    background-attachment: fixed;
  }
}

@media (max-width: 1199px) {
  #header {
    .container {
      max-width: 100%;
    }
  }

  .nav-menu {
    a {
      padding: 8px 4px;
    }
  }
}

@media (max-width: 991px) {
  #header {
    background: rgba(6, 12, 34, 0.98);
    height: 70px;
    padding: 15px 0;
    transition: all 0.5s;
  }

  #nav-menu-container {
    display: none;
  }

  #mobile-nav-toggle {
    display: inline;
  }

  #intro {
    .intro-container {
      top: 70px;
    }

    h1 {
      font-size: 34px;
    }

    p {
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}

@media (max-width: 767px) {}

@media (max-width: 574px) {}

@media (max-width: 480px) {}

@media (max-width: 460px) {}